/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap');

body {
  overflow: hidden;
}

.Dashboard {
  height: 100vh;
  width: 100vw;
  background-color: #292e31;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.total-yearly-revenue {
  font-size: 45px;
  color: white;
  font-weight: 600;
  margin-top: 5px;
  z-index: 22;
}
.total-monthly-revenue {
  font-size: 16px;
  color: white;
  font-weight: 600;
  z-index: 22;
  margin-bottom:3px;
}

.total-monthly-revenue-text {
  margin-top: 10px;
  position: absolute;
  top: 20px;
  font-size: 28px;
  color: white;
  font-weight: 600;
  z-index: 222232;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-kpis {
  margin-top: 10px;
  font-size: 17px;
  color: white;
  font-weight: 600;
  z-index: 222232;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.monthly-kpis > span {
  margin-bottom: 13px;
}

.kpi-divider {
  z-index: 22;
  background-color: #fff;
  height: 1px;
  width: 280px;
  margin: 0 0 20px 0;
}

.average-transaction,
.total-cleanings {
  color: white;
  font-size: 16px;
  z-index: 23232;
  margin-top: 10px;
}

.two-maids-logo {
  margin-top: 10px;
  top: 0px;
  left: 0px;
  padding: 20px;
  position: absolute;
  width: 160px;
}

.legend {
  text-align: center;
  width: 310px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: black;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 8px;
  font-size: 14px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 20px;
}

.legend-item:last-child {
  margin-bottom: 0px;
}

.legend-item {
  display: flex;
  color: white;
  margin-bottom: 6px;
  align-items: center;
  text-align: left;
}

.legend-item > img {
  width: 20px;
  height: 20px;
}

.legend-item > hr {
  width: 70px;
  margin-left: 18px;
  margin-right: 18px;
  background-color: #328CD5;
  height: 2px;
  border: none;
}

.imageSize {
  width: 50px !important;
  height: 30px !important;
}
footer {
  /* width: 100%; */
  color: white;
  font-size: 12px;
  padding: 20px;
  opacity: 0.4;
  position: absolute;
  left: 0;
  bottom: 0;
}

footer > p {
  margin: 0;
}

#coins {
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.legend-item span {
  width: 180px;
}

.kpi-group {
  min-width: 300px;
  display: flex;
  justify-content: space-between;
}

.result {
  color: #328CD5;
}

@media (max-width: 600px) {
  .total-online-user-text {
    font-size: 8px;
    color: white;
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 600;
    z-index: 22;
    position: absolute;
    top: 40px;
    right: 20px;
  }

  .total-monthly-revenue {
    font-size: 10px;
    color: white;
    font-weight: 600;
    margin-top: 5px;
    z-index: 22;
  }

  .total-monthly-leads {
    font-size: 8px;
    color: white;
    font-weight: 600;
    margin-top: 5px;
    z-index: 222232;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
  }

  .total-monthly-revenue-text {
    font-size: 12px;
    color: white;
    font-weight: 600;
    margin-top: 5px;
    margin-top: 20px;
    z-index: 222232;

    /* margin-right: 20px; */
  }

  .two-maids-logo {
    top: 0px;
    left: 0px;
    padding: 20px;
    position: absolute;
    width: 50px;
  }

  .legend {
    width: 265px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    background-color: black;
    /* padding-right: 25px; */
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
    font-size: 14px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .legend-item:last-child {
    margin-bottom: 0px;
  }

  .legend-item {
    display: flex;
    color: white;
    margin-bottom: 6px;
  }

  .legend-item > img {
    width: 12px;
    height: 20px;
  }

  .legend-item > hr {
    max-width: 30px;
    margin-left: 18px;
    margin-right: 18px;
    background-color: #328CD5;
    height: 2px;
    border: none;
  }

  footer {
    /* width: 100%; */
    color: white;
    font-size: 5px;
    padding: 20px;
    opacity: 0.4;
    position: absolute;
    left: 0;
    bottom: 30%;
  }
}
@media (max-width: 960px) {
  .two-maids-logo {
    width: 120px;
  }

  .total-monthly-revenue,
  .total-monthly-revenue-mm {
    font-size: 28px !important;
  }

  .total-monthly-revenue-text {
    font-size: 16px;
  }

  .average-transaction,
  .total-cleanings {
    font-size: 12px;
  }

  .monthly-kpis {
    font-size: 11px;
  }

  .monthly-kpis > span {
    margin-bottom: 10px;
  }

  .kpi-group {
    min-width: 185px;
  }

  .kpi-divider {
    height: 0.5px;
    width: 140px;
    margin-bottom: 10px;
  }

  .legend {
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 165px;
    font-size: 8px !important;
  }
  .legend-item > hr {
    width: 50px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .legend-item > img {
    width: 12px;
    height: 12px;
  }
  .legend-item > span {
    width: 105px;
  }
  footer {
    font-size: 8px;
  }
}

:focus {
    outline: -webkit-focus-ring-color auto 3px;
}
