.rsm-svg {
  background-color: #292e31;
  max-width: 90vw;
  max-height: 90vh;
  margin-top: 90px;
}

@media (max-width: 600px) {
  .rsm-svg {
    background-color: #292e31;
    max-width: 100vw;
    max-height: 90vh;
    margin-top: 0px;
  }
}